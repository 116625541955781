
.badge {
    font-size: 100%;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
}

html, body, #root {
    height: 100%;
}
  
.sync-container {
    z-index: 9999;
    position: sticky;
    display: flex;
    justify-content: center;
    background-color: rgba(0,0,0,0.4);
    height: 100%;
    align-items: center;
    flex-direction: column;
}

body {
    font-size: 14px;
    font-family: 'OpenSans';
    font-weight: 300;
    height: 100%;
    margin: 0;
    --spacing: 16px;
    --theme-color1: #26a69a;
    --theme-color2: #29b6f6;
    --theme-color3: #2055aa;
    --theme-color4: #184ea3;
    --bg-color1: #f5f5f5;
    --bg-color2: #dee2e6;
    margin: 0;
    padding: 0;
}

.badge-female {
    color: #fff;
    background-color: #f274db;
}

.badge-male {
    color: #fff;
    background-color: #86d3ff;
}

a {
color: var(--theme-color1);
font-weight: 400;
}

.card-header {
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: 400;
} 

.card-header h5>span.badge{
font-size: 1.2rem;
}

.user.admin {
    color: #ffc107;
}

.iKUhSX {
    margin-bottom: 1rem !important;
} 


  
    