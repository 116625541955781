
@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans-Regular.ttf');
    font-weight: normal;
  }
  
  @font-face {
   font-family: 'OpenSans';
   src: url('../fonts/OpenSans-Light.ttf');
   font-weight: 300;
  }
  
  @font-face {
   font-family: 'OpenSans';
   src: url('../fonts/OpenSans-Bold.ttf');
   font-weight: 700;
  }
  
  @font-face {
   font-family: 'OpenSans';
   src: url('../fonts/OpenSans-SemiBold.ttf');
   font-weight: 600;
  }

  main {
    padding: 15px;
  }

  fieldset {
    background-color: #fafafa;
    /*box-shadow: 1px 1px 1px 0px #dedede;*/
    border: 1px solid #dedede;
    padding: 10px;
    border-radius: 3px;
    flex-grow: 1;
  }

  fieldset:not(:first-of-type){
    margin-left: 20px;
  }

  legend {
    margin: 0px 10px;
    margin-left: 0;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    color: var(--theme-color1);
    width: auto;
  }

  .notified {
    box-shadow: 0px 0px 6px 2px #00BFA5;
  }

  .action-menu {
      margin-bottom: 1rem;
      padding: 0.5rem;
      border-radius: 3px;
      background-color: #fafafa;
      border: 1px solid #ccc;
  }

  .action-menu button:not(:last-of-type), .action-menu a:not(:last-of-type) {
    margin-right: 1rem;
  }

  .ui-divider {
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .ui-divider > h4 {
    cursor: pointer;
    white-space: nowrap;
    margin-left: 0rem;
    margin-bottom: 0px;
    margin-right: 1rem;
    color: var(--theme-color1);
    font-size: larger;
  }

  .ui-divider::after{
    content: '';
    width: 50%;
    border-bottom: 1px solid transparent;
    box-shadow: 0px 1px 1px 0px #ccc;
  }
  .ui-divider.active::after{
    box-shadow: 0px 1px 1px 0px var(--theme-color1);
    transition: 0.3s box-shadow;
  }
  .ui-divider.active::before{
    box-shadow: 0px 1px 1px 0px var(--theme-color1);
    transition: 0.3s box-shadow;
  }

  .ui-divider::before{
    content: '';
    width: 50%;
    border-bottom: 1px solid transparent;
    box-shadow: 0px 1px 1px 0px #ccc;
  }

  table.table tr.selected {
    background-color: var(--theme-color1);
    color: white;
  }

  .col-form-label {
    padding-top: 0;
    padding-bottom: 0;
    line-height: normal;
  }

  .form-group.row > div.col-sm-1 > p,
  .form-group.row > div.col-sm-2 > p,
  .form-group.row > div.col-sm-3 > p,
  .form-group.row > div.col-sm-4 > p,
  .form-group.row > div.col-sm-5 > p,
  .form-group.row > div.col-sm-6 > p,
  .form-group.row > div.col-sm-7 > p,
  .form-group.row > div.col-sm-8 > p,
  .form-group.row > div.col-sm-9 > p,
  .form-group.row > div.col-sm-10 > p,
  .form-group.row > div.col-sm-11 > p,
  .form-group.row > div.col-sm-12 > p {
    margin-bottom: 0;
  }

  b, strong, label {
    font-weight: 600;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .internal-link {
    color: #333 !important;
    font-weight: bold;
    text-decoration: underline !important;
    cursor: pointer;
    padding: 0.5rem !important;
    border-radius: 2px;

  }

  .internal-link:hover {
    background-color: var(--theme-color1);
    color: white !important; 
    
  }

  .was-validated .form-control:invalid, .form-control.is-invalid {
    background-color: #ffdee2;
    color: #dc3545;
  }

  form {
    margin: 4px;
  }

.ui-divider::before {
    display:none;
}

.ui-divider::after {
    width: 100%;
}

.form-group {
  margin-bottom: 0.4rem;
}

.fieldsets {
  margin-bottom: 20px;
  margin-top: 20px;
}

.hDvUhb {
  margin-bottom: 1rem !important;
}

.badge.badge-dark {
  background-color: var(--theme-color1);
}

h2 > .badge {
  font-weight: 400;
  font-size: 70% !important;
  position: relative;
  top: -2px;
}

h3 > .badge {
  font-weight: 400;
  font-size: 70% !important;
  position: relative;
  top: -2px;
}

h4 > .badge {
  font-weight: 400;
  font-size: 70% !important;
  position: relative;
  top: -2px;
}

h5 > .badge {
  font-weight: 400;
  font-size: 85% !important;
  position: relative;
  top: -1px;
}

.badge.badge-male, .badge.badge-female {
  color: #fff;
  background-color: #6c757d
}

label.col-form-label, label.app-form-label {
  font-weight: normal !important;
  color: #888;
  padding-right:0.25rem;
}

.form-value {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 110%;
}

table.table tr.selected {
  background-color: #dedede;
  color: black;
  font-weight: 600;
}

.sc-AxhUy {
  border:0;
}

.btn-danger {
  background-color: #e8717d;
}

.exam-status-badge {
  font-weight: 300;
  font-size: small;
}

.exam-status-badge .badge {
  width: 1.5rem;
  height: 1.5rem;
}

.card-with-status {
  border-left-width: 0.75rem;
}

.alert {
  border: 1px solid #ccc;
  box-shadow: none;
}

.alert-info {
  border-color: #17a2b8;
}

.alert-success {
  border-color: #28a745;
}

.alert-danger {
  border-color: #dc3545;
}

.alert-warning {
  border-color: #ffc107;
}

.card-selected {
  box-shadow: 0 0 20px 2px #77deee;
}

.card-selected h5 {
  text-decoration: underline;
  font-weight: bold;
}

.card-body {
  padding-top:0;
}

input.form-control[type=file],input.form-control[type=file]:focus {
  display:none;
}

.custom-file-upload {
  margin-bottom: 0;
}

.close-x {
  float:right;
  font-size:large;
  font-weight: bold;
  position:relative;
  top:-0.5rem;
  left:0.5rem;
  cursor:pointer;
}

.file-list {
  padding-left:1rem;
  margin-top:10px;
}

.download-btn {
  color: var(--theme-color1);
}

.app-version {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  background-color: #343a40 !important;
  color: white;
  padding: 8px;
  font-size: 75%;
  cursor: pointer;
}

.app-version > span {
  margin-left:8px;
  color: #ffc107;
}

.card-header.sticky {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  display: none;
}

.react-datepicker {
  top: -10px;
  border-radius: 0;
}

.react-datepicker__header {
  padding: 16px;
  padding-bottom: 0;
}

.table-hover tbody tr.form_section_space:hover {
  background-color: transparent;
}

tr.form_section_space td {
  background-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  height: 20px;
}

.form-table td {
  padding: 0.4rem !important;
  vertical-align: middle;
}

.rc-slider .rc-slider-handle, .rc-slider .rc-slider-dot-active {
  border-color: var(--theme-color1) !important;
}

.rc-slider .rc-slider-track {
  background-color: var(--theme-color1) !important;
}

.color-even-disabled.rc-slider-disabled .rc-slider-handle, .color-even-disabled.rc-slider-disabled .rc-slider-dot-active {
  border-color: var(--theme-color1) !important;
}

.color-even-disabled.rc-slider-disabled .rc-slider-track {
  background-color: var(--theme-color1) !important;
}

.btn-xs {
  padding: 0 2px 0 2px;
}

.btn-logs {
  position: relative;
  top: -0.3rem;
  left: 0.3rem;
}

.exam-table-container table td:nth-child(1), .exam-table-container table td:nth-child(7),.exam-table-container table td:nth-child(2) {
  width: 1.7rem;
  min-width: 1.7rem;
}

.exam-table-container table td:nth-child(3) {
  width: 9rem;
  min-width: 9rem;
}

.exam-table-container table td:nth-child(5), .exam-table-container table td:nth-child(6) {
  width: 9rem;
  min-width: 9rem;
}

.temporary-user.exam-table-container table td:nth-child(3) {
  width: unset;
  min-width: unset;
}

.open-exams.exam-table-container table td:nth-child(6), .open-exams.exam-table-container table th:nth-child(6) {
  display: none;
}

.temporary-user.exam-table-container table td:nth-child(5), .temporary-user.exam-table-container table th:nth-child(5) {
  display: none;
}

.for-patient.exam-table-container table td:nth-child(3), .for-patient.exam-table-container table th:nth-child(3) {
  display: none;
}

.invited-exams table td:nth-child(6), .invited-exams table th:nth-child(6),
.search-exams table td:nth-child(5), .search-exams table th:nth-child(5) {
  display: none
}

.temporary-user.invited-exams table td:nth-child(6), .temporary-user.invited-exams table th:nth-child(6) {
  display: table-cell;
}

.fHgwob {
  margin-bottom: 1rem !important;
}

.tab-pane {
  color: #495057;
  background-color: var(--bg-color1);
  padding: 1rem;
  padding-top: 1rem;
}

.login-tabs .tab-content {
  border: 1px solid #dee2e6;
  border-top:0;
}

#consultant-selector-tabpane-cst, #consultant-selector-tabpane-other, #consultant-selector-tabpane-external {
  border: 1px solid #dee2e6;
  border-top: 0;
}

.clickable {
  cursor: pointer;
}

.login-loading-outer {
  position: absolute;
  width: 100%;
  z-index: 10000;
  background-color: white;
  height: 100%;
  opacity: 0.4;
  left: 0;
  padding-top: 200px;
}

.media-card.card {
  margin-right: 1rem;
  min-width:250px;
}

.media-card.card .card-body {
  padding: 0.5rem;
}

.media-card.card .card-body .card-title.h5 {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0.3rem;
}

.media-card.card .card-body .mb-1 label {
  display: none;
}

.media-card.card .card-body .mb-1 {
  font-size: 13px;
}

.image-source span {
  font-size: small;
}

.form-table.medical-data label {
  text-decoration: underline;
  font-weight: 300;
}

.form-table.medical-data .form_section_space {
  display: none;
}

.minimal-td {
  width: 1px !important;
}

.yesno-label {
  color:  white;
  font-weight: bold;
  padding-top:2px;
}

#tabs-tabpane-login, #tabs-tabpane-reset {
  padding: 16px;
}

.specialization-badge {
  margin-right: 4px;
}

#async-user-ac > a {
  border-bottom: 1px solid #ddd;
}

.fastexamcard img {
  width: 150px;
  height: auto;
  border: 1px solid #ccc;
  margin-right: 4px;
  margin-bottom: 4px;
}