

.media-card {
    .check-container {
        display: flex;
        justify-content: center;
        background-color: var(--theme-color1);        
    }
}

.img-drag {
    overflow:hidden;
}

.img-tools {
    opacity: 0.75;
    background-color: white;
    color: #26a69a;
    font-size: 200%;
    padding: 0;
    margin-right: 4px;
    line-height: 1;
    margin-bottom: 4px;
}

.img-tools-bar {
    position: absolute;
    width: auto;
    display: inline-block;
    z-index: 1000;
} 

.img-tools-bar-inner {
    position: relative;
    top: 4px;
    left: 4px;
} 

.image-card-container {
    height: 250px;
    max-height: 250px;
    overflow: hidden;
    border: 1px solid rgb(221, 221, 221);
    margin-bottom: 10px;
    text-align: center;
}

.modal-90w {
    width: 90%;
    max-width: none!important;
    &.comparator {
        .modal-content {
            /*background-color: rgba(0,0,0,0.2);*/
            .modal-body{
                padding: 0!important;
                .img-drag{
                    border: 1px solid var(--theme-color1);
                    img {
                        width: 100%;
                        cursor: pointer;
                        position: relative;
                        transform: rotate(0deg);
                    }
                }
            }
        }
        .action-menu {
            background-color: rgba(255,255,255,0.5);
            box-shadow: none;
            margin-bottom: 0;
        }
        .comparator-images {
            display: flex;
            width: 100%;
            padding: 1rem;
        }
        .comparator-images .img-drag-wrapper {
            width: calc(100% / 3);
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
}
.comments-container {
    max-height: 500px;
    overflow: auto;
    .media-comment {
        .author {
            margin: 0;
            font-weight: 600;
        }
        .time {
            font-size: 0.8rem;
            margin: 0;
        }
    }
}

.img-drag-wrapper {
    width: 60%;
    > div {
        overflow: hidden;
        width: 100%;
        img {
            width: 100%;
        }
    }
}

.image-slider {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1rem;
}

.zoom-wrapper {
    border: 2px solid black;
    box-shadow: 0px 0px 34px -3px rgba(0,0,0,0.68);
}

.zoom-wrapper img {
    /* debug border: 1px solid green; */
}