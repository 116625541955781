
.navbar {
    top: 0;
    position: sticky;
    z-index: 1040;
}

.sidebar {
    button {
        height: 28px;
        width: 60px;
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: none;
        border: 0;
        text-align: center;
        outline: none;
        color: transparent;
        margin: 1rem;
        background-color: var(--theme-color1);
        @media (min-width: 768px) {
            display: none;
        }
        &.hide-menu > .content {
            opacity: 0;
        }
        &.hide-menu:before {
            transform: rotate(-45deg) translate3d(0, -50%, 0);
            top: 50%;
        }
        &.hide-menu:after {
            transform: rotate(45deg) translate3d(0, 50%, 0);
            bottom: 50%;
        }
        &:before {
            top: 0;
        }
        &:after {
            bottom: 0;
        }
        &:before, &:after, .content{
            content: "";
            position: absolute;
            width: 40px;
            height: 4px;
            background-color: #333;
            transition: all ease 0.3s;
            border-radius: 4px;
        }
    }
    @media (min-width: 768px) {
        position: sticky;
        height: calc(100vh - 3.5rem);
        top: 3.5rem;
        margin: 0;
    }
    padding: 0px;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: var(--theme-color1);
    
    a.nav-link {
        @media (min-width: 768px) {
            /*padding: 20px;
            font-size: 1.1rem;*/
        }
        font-size: 1rem;
        padding: 10px;
        color: white;
        transition: .5s background-color;
    }
    a.nav-link:hover, a.nav-link.active:hover {
        background-color: #208c83;
    }
    a.nav-link.active {
        background-color: #239e93;
        border: 1px solid #208c83;
    }
}

span.error {
    color: crimson;
    font-weight: bold;
}

span.success {
    color: #28a745;
    font-weight: bold;;
}

.navbar-text {
    span.user {
        cursor: pointer;
        transition: .2s color;
    }
    span.user:hover {
        color: white;
        transition: .2s color;
    }
}

.login-loading {
    font-size: x-large;
    width: 4rem;
    height: 4rem;
    margin-top: 4rem;
    color: #17a2b8!important;
}

.card-header > div {
    font-size: 1.1rem;
}

.chat-messages {
    overflow-y: auto;
    background-color: #e6e6e6;
    padding: 10px;
    border-radius: 10px;
}

.chat-messages .card-body {
    margin: 12px !important;
    padding: 0 !important;
}

.chat-messages .card-text {
    white-space: break-spaces;
}

.chat-messages .who {
    display: block;
}

.chat-messages .date {
    font-style: italic;
    float:right;
    display: block;
}

.chat-insert {
    position: fixed;
    bottom: 10px;
    width: 80%;
}

.chat-insert .card-body {
    padding: 8px;
    /*box-shadow: 0px 0px 4px 2px #ccc;*/
}

.tab-pane {
    padding:0;
    padding-top:1rem;
}

.tab-contents {
    width: 100%;    
}

.main-search-filter {
    width: 50%;
    border-radius: 3px;
    overflow: hidden;
    padding-left: 1px;
}

.chat-arrow {
    font-size: 30px;
    line-height: 0.4;
}

.as-link {
    cursor:pointer;
    text-decoration: underline !important;
}

.pull-right {
    float: right;
}

table.app-table td {
    vertical-align: middle;
}

.callout {
    width: 40%;
    border-radius: 20px;
    margin-bottom: 20px;
    box-shadow:0 0 10px #c1c1c1;
}

.callout.fromMe {
    background-color: lightgreen;
    margin-left:60%;
}

.callout.fromMe:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: 5%;
    bottom: -26px;
    border: 10px solid transparent;
    border-top: 16px solid lightgreen;
}

.callout.forMe:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 5%;
    bottom: -26px;
    border: 10px solid transparent;
    border-top: 16px solid lightblue;
}

.callout.forMe {
    background-color: lightblue;
}

.callout.others:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 5%;
    bottom: -26px;
    border: 10px solid transparent;
    border-top: 16px solid lightyellow;
}

.callout.others {
    background-color: lightyellow;
}


.invitation_userlist_container {
    padding: 16px;
    height: 500px;
    overflow-y: auto;
    background-color: white;
}

.invitation_userlist_container > div {
    border-bottom: 1px solid #ccc;
    padding: 4px;
    cursor: pointer;
}

.invitation_userlist_container > div.selected {
    background-color: #d1ecf1;
    border: 2px solid  #17a2b8;
}

.invitation_userlist_container .highlight {
    font-weight: 500;
}

#consultant-selector-tabpane-cst .form-group.col, #consultant-selector-tabpane-other .form-group.col {
    border-bottom: 1px solid #ddd;
    padding-bottom: 16px;
    margin: 0;
}

.modal-header {
    font-weight: bold;
}

.close > span {
    font-size: 20px;
}

.reveal-password {
    color: #009688;
    z-index: 1000;
    float: right;
}

.reveal-password > div {
    top: 32px;
    left: -8px;
    position: relative;
    cursor: pointer;
}

.panel-h4 {
    z-index: 20;
}

.panel-hint {
    position:absolute;
    z-index:10;
}

.panel-hint > div {
    position: relative;
    top: 20px;
    font-style: italic;
}

a#tabs-tab-close {
    font-weight: bold;
}

.custom-file-upload {
    font-weight: 400;
    font-family: 'OpenSans';
    background-color: #26a69a;
    color: white;
    padding: 0.5em;
    border-radius: 3px;
    position: relative;
    top: 1px;
    margin-right: 10px;
}

#image-upload {
    display:none;
}

