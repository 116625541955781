
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

$theme-colors: (
  "primary": #26a69a
);

// Override default variables before the import
$body-bg: var(--bg-color1);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
